import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

import History from './history';
import Extra from './extra';

const HistoryDrawer = ({
  open,
  setOpen,
  handleChatOpen,
  currentChatId,
}) => (
  <Drawer
    className="history-drawer"
    zIndex={1032}
    width={400}
    closable
    open={open}
    onClose={() => setOpen(false)}
    styles={{
      header: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        padding: '16px 12px',
      },
    }}
    extra={(
      <Extra handleChatOpen={handleChatOpen} />
    )}
  >
    <History handleChatOpen={handleChatOpen} currentChatId={currentChatId} />
  </Drawer>
);

HistoryDrawer.defaultProps = {
  open: false,
  setOpen: () => {},
  handleChatOpen: () => {},
  currentChatId: null,
};

HistoryDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleChatOpen: PropTypes.func,
  currentChatId: PropTypes.number,
};

export default HistoryDrawer;
