import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Space,
  Input,
} from 'antd';

import {
  ArrowUpOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const UserInput = ({ inProgress, addUserMessage }) => {
  const [message, setMessage] = useState('');

  const handleSendMessage = useCallback(async () => {
    if (message.trim().length === 0) return;

    addUserMessage({ content: message, role: 'user' });
    setMessage('');
  }, [addUserMessage, message, setMessage]);

  return (
    <Space id="new-message" className="user-input">
      <Input.TextArea
        autoSize
        size="large"
        placeholder="Type your message here..."
        value={message}
        disabled={inProgress}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
          }
        }}
      />
      <Button
        type="primary"
        style={{ height: '40px', width: '40px' }}
        onClick={handleSendMessage}
        disabled={inProgress}
      >
        {inProgress ? <LoadingOutlined /> : <ArrowUpOutlined />}
      </Button>
    </Space>
  );
};

UserInput.defaultProps = {
  inProgress: false,
  addUserMessage: () => {},
};

UserInput.propTypes = {
  inProgress: PropTypes.bool,
  addUserMessage: PropTypes.func,
};

export default UserInput;
