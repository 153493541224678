/* global window */
import * as amplitude from '@amplitude/analytics-browser';

// from Amplitude docs: API Keys are public. They aren't vulnerable to leaks or compromises.
amplitude.init('86b4328c164ca0eed54c4d18826c0324', { defaultTracking: false });

export function setAmplitudeUser(userId, companyId, country, ip) {
  amplitude.setUserId(userId);

  const identify = new amplitude.Identify();
  identify.set('company_id', companyId);
  identify.set('company_country', country);
  identify.set('ip_address', ip);
  amplitude.identify(identify);
}

export function trackAmplitudeEvent(eventName, properties = {}) {
  amplitude.logEvent(eventName, properties);
}

window.setAmplitudeUser = setAmplitudeUser;
window.trackAmplitudeEvent = trackAmplitudeEvent;
