import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Drawer, Result } from 'antd';
import axios from 'axios';

import Extra from './extra';
import Messages from './messages';
import UserInput from './user-input';

const ChatDrawer = ({
  open,
  setOpen,
  handleHistoryOpen,
  setChatId,
  chatId,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [messages, setMessages] = useState([]);

  const clearChat = () => {
    setChatId(null);
    setMessages([]);
  };

  const loadChat = useCallback(async () => {
    if (chatId) {
      const response = await axios.get(`/cwo_gpt/chats/${chatId}`);
      setMessages(response.data.messages || []);
    }
  }, [setMessages, chatId]);

  useEffect(() => { loadChat(); }, [chatId, loadChat]);

  const handleBotResponse = useCallback((data) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages.at(-1);
      if (lastMessage.content === data.content) return prevMessages;

      const newMessages = [...prevMessages];
      if (lastMessage.role === 'pending') {
        lastMessage.content = data.content;
        lastMessage.role = 'assistant';
      } else {
        lastMessage.content += data.content;
      }
      newMessages[newMessages.length - 1] = lastMessage;

      return newMessages;
    });
  }, [setMessages]);

  const botResponder = useCallback((message) => {
    const eventSource = new EventSource(`/cwo_gpt/bot_response?id=${chatId || ''}&message=${message}`); // eslint-disable-line no-undef
    eventSource.addEventListener('close', (event) => {
      if (!chatId) setChatId(JSON.parse(event.data).chat_id);
      setInProgress(false);
      eventSource.close();
    });
    eventSource.onerror = () => {
      setInProgress(false);
      eventSource.close();
    };
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      handleBotResponse(data);
    };
  }, [chatId, setChatId, setInProgress, handleBotResponse]);

  const addUserMessage = useCallback(async (message) => {
    setInProgress(true);
    setMessages([...messages, { content: message.content, role: 'user' }, { content: 'Thinking...', role: 'pending' }]);
    botResponder(message.content);
  }, [messages, setInProgress, setMessages, botResponder]);

  return (
    <Drawer
      className="chat-drawer"
      zIndex={1032}
      width={400}
      closable
      open={open}
      onClose={() => setOpen(false)}
      styles={{
        header: {
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          padding: '16px 12px',
        },
        footer: {
          textAlign: 'right',
          padding: '0',
          fontSize: '12px',
          color: '#696F88',
        },
      }}
      extra={(
        <Extra handleHistoryOpen={handleHistoryOpen} handleNewChat={clearChat} />
      )}
      footer={(
        <div>
          <UserInput inProgress={inProgress} addUserMessage={addUserMessage} />
          <div style={{ textAlign: 'center', padding: '12px', backgroundColor: '#F3F4F6' }}>
            AI Chat may make mistakes. We recommend verifying important information.
          </div>
        </div>
      )}
    >
      {messages.length > 0 ? <Messages messages={messages} /> : (
        <div>
          <Result
            icon={<img src="/favicon.ico" alt="AI chat" />}
            title="CwoGPT"
            subTitle="Start a new chat"
          />
        </div>
      )}
    </Drawer>
  );
};

ChatDrawer.defaultProps = {
  open: false,
  setOpen: () => {},
  handleHistoryOpen: () => {},
  setChatId: () => {},
  chatId: null,
};

ChatDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleHistoryOpen: PropTypes.func,
  setChatId: PropTypes.func,
  chatId: PropTypes.number,
};

export default ChatDrawer;
