import React from 'react';
import CWOConfigProvider from 'helpers/cwoConfigProvider';
import Component from 'components/cwo-gpt';

const CwoGpt = () => (
  /*
    NOTE: Special case !!!
    Start bot button is always visible, so don't need lazy load.
  */

  <CWOConfigProvider>
    <Component />
  </CWOConfigProvider>
);

export default CwoGpt;
