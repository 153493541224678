import React from 'react';
import PropTypes from 'prop-types';
import { Space, Button } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

const Extra = ({ handleChatOpen }) => (
  <Space id="season-panel" className="cwo-gpt-chat__title">
    <Button onClick={handleChatOpen} title="Your Chat"><CommentOutlined /></Button>
    <span><b>History</b></span>
  </Space>
);

Extra.defaultProps = {
  handleChatOpen: () => {},
};

Extra.propTypes = {
  handleChatOpen: PropTypes.func,
};

export default Extra;
