import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Message from './message';

const Messages = ({ messages }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="cwo-gpt-messages">
      {messages.map((message) => {
        const key = message.content.length + message.role + Math.random();
        return <Message key={key} content={message.content} role={message.role} />;
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

Messages.defaultProps = {
  messages: [],
};

Messages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    role: PropTypes.string,
  })),
};

export default Messages;
