import React from 'react';
import PropTypes from 'prop-types';
import { Space, Button } from 'antd';
import { HistoryOutlined, ClearOutlined } from '@ant-design/icons';

const Extra = ({ handleHistoryOpen, handleNewChat }) => (
  <Space id="season-panel" className="cwo-gpt-chat__title">
    <Button onClick={handleHistoryOpen} title="History"><HistoryOutlined /></Button>
    <Button onClick={handleNewChat} title="New Chat"><ClearOutlined /></Button>
    <span><b>Your AI assistant for CWO</b></span>
  </Space>
);

Extra.defaultProps = {
  handleHistoryOpen: () => {},
  handleNewChat: () => {},
};

Extra.propTypes = {
  handleHistoryOpen: PropTypes.func,
  handleNewChat: PropTypes.func,
};

export default Extra;
