import React, { lazy, Suspense } from 'react';
import CWOConfigProvider from 'helpers/cwoConfigProvider';
import Loading from 'helpers/loading/loading';

const Component = lazy(() => import('components/cwo-gpt/admin'));

const CwoGptAdmin = () => (
  <CWOConfigProvider>
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  </CWOConfigProvider>
);

export default CwoGptAdmin;
