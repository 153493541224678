import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'marked-react';

const Message = ({ content, role }) => (
  <div className={`message ${role === 'user' ? 'outgoing' : 'incoming'}`}>
    <div className="message-content">
      {role !== 'user' && (
        <div className="bot-icon">
          <img src="/favicon.ico" alt="AI chat" />
        </div>
      )}
      <div className="message-text" style={{ flexDirection: 'column', display: 'flex' }}>
        <Markdown>{content}</Markdown>
      </div>
    </div>
  </div>
);

Message.defaultProps = {
  content: '',
  role: 'user',
};

Message.propTypes = {
  content: PropTypes.string,
  role: PropTypes.string,
};

export default Message;
