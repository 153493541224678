import React, { useState, useCallback } from 'react';
import { FloatButton } from 'antd';
import { RobotOutlined } from '@ant-design/icons';

import ChatDrawer from './chat-drawer';
import HistoryDrawer from './history-drawer';

const CwoGptChat = () => {
  const [chatId, setChatId] = useState(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  const handleHistoryOpen = useCallback(() => {
    setChatOpen(false);
    setHistoryOpen(true);
  }, [setHistoryOpen, setChatOpen]);

  const handleChatOpen = useCallback((id) => {
    setChatId((prevId) => {
      if (typeof id === 'number' && id !== prevId) return id;
      return prevId;
    });
    setHistoryOpen(false);
    setChatOpen(true);
  }, [setChatId, setChatOpen, setHistoryOpen]);

  return (
    <div>
      <FloatButton icon={<RobotOutlined />} onClick={() => handleChatOpen()} />

      <ChatDrawer
        open={chatOpen}
        setOpen={setChatOpen}
        handleHistoryOpen={handleHistoryOpen}
        setChatId={setChatId}
        chatId={chatId}
      />
      <HistoryDrawer
        open={historyOpen}
        setOpen={setHistoryOpen}
        handleChatOpen={handleChatOpen}
        currentChatId={chatId}
      />
    </div>
  );
};

export default CwoGptChat;
