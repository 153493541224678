import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { List, Typography } from 'antd';
import axios from 'axios';

const History = ({ handleChatOpen, currentChatId }) => {
  const [history, setHistory] = useState([]);
  const prevChatId = useRef();

  const loadHistoryItems = async () => {
    const response = await axios.get('/cwo_gpt/chats');
    setHistory(response.data);
  };

  useEffect(() => {
    loadHistoryItems();
  }, []);

  useEffect(() => {
    if (prevChatId.current === null && currentChatId !== null) {
      loadHistoryItems();
    }
    prevChatId.current = currentChatId;
  }, [currentChatId]);

  const openChat = (chatId) => {
    prevChatId.current = chatId;
    handleChatOpen(chatId);
  };

  return (
    <div>
      {history.map((items) => (
        <List
          key={items.date + Math.random()}
          size="small"
          header={items.date}
          dataSource={items.chats}
          bordered={false}
          style={{ border: 'none' }}
          renderItem={(item) => (
            <List.Item key={item.id} style={{ border: 'none' }}>
              <Typography.Link onClick={() => openChat(item.id)}>
                {item.title || item.created_at}
              </Typography.Link>
            </List.Item>
          )}
        />
      ))}
    </div>
  );
};

History.defaultProps = {
  handleChatOpen: () => {},
  currentChatId: null,
};

History.propTypes = {
  handleChatOpen: PropTypes.func,
  currentChatId: PropTypes.number,
};

export default History;
